import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { Button, Typography } from 'components/common';

import variables from 'assets/scss/variables.scss';
import { Input } from 'components/common/Input';
import { ICar, IReservation, APIStatus } from 'types';
import * as api from 'api';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Tooltip } from 'react-tooltip';

interface Props {
  reservation: IReservation;
  isOpen: boolean;
  onCancelledTrip?: (res: IReservation) => void;
  onChangeCar?: (res: IReservation) => void;
  onClose?: () => void;
}

interface ChangeCarPayload {
  car_id: number;
  pickup_location_id?: number;
  return_location_id?: number;
}

const ModifyReservation = ({
  reservation,
  isOpen = false,
  onCancelledTrip,
  onChangeCar,
  onClose,
}: Props) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [showCloseTripFlow, setShowCloseTripFlow] = useState(false);
  const [showChangeCarFlow, setShowChangeCarFlow] = useState(false);
  const [closeTripReason, setCloseTripReason] = useState('');

  const [showSameLocationFlow, setShowSameLocationFlow] = useState(false);
  const [showDifferentLocationFlow, setShowDifferentLocationFlow] = useState(false);

  const [substituableCars, setSubstituableCars] = useState<ICar[]>([]);
  const [changeCarPayload, setChangeCarPayload] = useState<ChangeCarPayload>();
  const [selectedCar, setSelectedCar] = useState<ICar | null>(null);

  const [cancelTripStatus, setCancelTripStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [changeCarStatus, setChangeCarStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isVisible && reservation) {
      const fetchSubstituableCars = async () => {
        try {
          const data = await api.getOwnerSubstituableCars({
            start_time: reservation.start_time,
            end_time: reservation.end_time,
          });
          setSubstituableCars(data);
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.log('error', error);
        }
      };

      fetchSubstituableCars();
    }
  }, [isVisible, reservation]);

  const handleClose = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setIsVisible(false);
    setChangeCarPayload(undefined);
    setSelectedCar(null);
  };

  const handleCancelReservation = async () => {
    const payload = {
      owner_cancellation_reason: closeTripReason,
    };

    setCancelTripStatus({ loading: true, success: false, error: null });

    try {
      const data = await api.cancelOwnerReservation({ payload, reservationId: reservation!.id });
      setCancelTripStatus({ loading: false, success: true, error: null });
      // eslint-disable-next-line no-unused-expressions
      onCancelledTrip && onCancelledTrip(data);
      handleClose();
      toast.success('Your cancellation request has been sent.');
    } catch (error: any) {
      setCancelTripStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  const handleCarChange = async () => {
    const payload = {
      ...changeCarPayload,
    };

    setChangeCarStatus({ loading: true, success: false, error: null });

    try {
      const data = await api.changeOwnerReservationCar({ payload, reservationId: reservation!.id });
      setChangeCarStatus({ loading: false, success: true, error: null });
      // eslint-disable-next-line no-unused-expressions
      onChangeCar && onChangeCar(data);
      handleClose();
      toast.success('The car has been changed successfully.');
    } catch (error: any) {
      setChangeCarStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isVisible}
      style={{
        overlay: {
          zIndex: 1000,
        },
        content: {
          zIndex: 1000,
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '29px',
          maxWidth: '90%',
          minWidth: '300px',
          minHeight: '500px',
          maxHeight: '90%',
        },
      }}
      onRequestClose={handleClose}
    >
      <div>
        {
          (
            cancelTripStatus.loading
            || changeCarStatus.loading
          )
          && (
            <LoadingSpinner />
          )
        }

        <div className="d-flex justify-content-end">
          <Button
            onClick={handleClose}
            style={{ width: '25px', height: '25px', padding: 0 }}
          >
            X
          </Button>
        </div>

        <div className="mt-4 d-flex flex-column align-items-center">
          <div className="d-flex flex-column gap-3 align-items-center">
            <Typography variant="h2">
              Modify Trip
            </Typography>
          </div>

          <div className="d-flex flex-row gap-3 mt-3">
            {
              !reservation.is_block
              && !reservation.owner_requested_to_cancel
              && (
                ['late', 'open', 'verified', 'confirmed', 'rental'].includes(reservation.status)
              ) && (
                <Button
                  onClick={() => {
                    setShowCloseTripFlow(true);
                    setShowChangeCarFlow(false);
                  }}
                  style={{ backgroundColor: variables.red3 }}
                >
                  Request to cancel
                </Button>
              )
            }

            {
              reservation.owner_requested_to_cancel && (
                <div
                  data-tooltip-id="cancel-trip-submitted"
                  data-tooltip-content="Cancellation request already submitted"
                >
                  <Button
                    onClick={() => setShowCloseTripFlow(true)}
                    style={{ backgroundColor: variables.red3 }}
                    disabled
                  >
                    Request to cancel
                  </Button>
                  <Tooltip id="cancel-trip-submitted" />
                </div>
              )
            }

            <Button
              onClick={() => {
                setShowChangeCarFlow(true);
                setShowCloseTripFlow(false);
              }}
              style={{ backgroundColor: variables.blue3 }}
            >
              Change car
            </Button>
          </div>

          {
            showCloseTripFlow && (
              <div className="d-flex flex-column mt-3 align-items-center text-center gap-3">
                <div style={{ width: '80%' }}>
                  {
                    reservation.status === 'rental'
                      ? (
                        <div>
                          <Typography>
                            Are you sure you want to cancel? The guest already has the instructions
                            and is on their way.
                          </Typography>

                          <Typography>
                            {`
                              You will miss out on $${reservation.owner_earnings.toFixed(2)}
                              in earnings and will take a $100 penalty for such a last minute cancellation.
                            `}
                          </Typography>
                        </div>
                      ) : (
                        <>
                          <Typography>
                            To avoid cancelation penalties and ensure a smooth experience for your guest,
                            try to move them into another car first.
                          </Typography>

                          <Typography>
                            You may be subject to a $50 cancelation penalty depending on the cancelation reason.
                          </Typography>
                        </>
                      )
                  }
                </div>

                <div style={{ width: '100%' }}>
                  <Input
                    type="textbox"
                    value={closeTripReason}
                    placeholder="Please provide a reason"
                    style={{ height: '150px', resize: 'none' }}
                    onChange={(e: any) => setCloseTripReason(e.target.value)}
                  />
                </div>

                <Button
                  onClick={handleCancelReservation}
                  style={{ backgroundColor: variables.red3 }}
                >
                  Submit cancellation request
                </Button>
              </div>
            )
          }

          {
            showChangeCarFlow && (
              <div className="d-flex flex-row gap-2 mt-4">
                <Button
                  onClick={() => {
                    setShowSameLocationFlow(true);
                    setShowDifferentLocationFlow(false);
                    setChangeCarPayload(undefined);
                    setSelectedCar(null);
                  }}
                  style={{ backgroundColor: variables.gray5 }}
                >
                  Same location
                </Button>

                <Button
                  onClick={() => {
                    setShowDifferentLocationFlow(true);
                    setShowSameLocationFlow(false);
                    setChangeCarPayload(undefined);
                    setSelectedCar(null);
                  }}
                  style={{ backgroundColor: variables.gray5 }}
                >
                  Different Location
                </Button>
              </div>
            )
          }

          {
            showSameLocationFlow && (
              <div className="d-flex flex-column mt-4 w-100 justify-content-center align-items-center gap-3">
                <div
                  className="d-flex flex-row gap-3 align-items-center justify-content-center"
                  style={{ width: '90%' }}
                >
                  <Typography>
                    Nearby cars
                  </Typography>

                  <div style={{ width: '70%' }}>
                    <Select
                      onChange={
                        (option: any) => {
                          setChangeCarPayload({
                            car_id: parseInt(option.value, 10),
                          });
                          setSelectedCar(substituableCars.find(c => c.id === parseInt(option.value, 10))!);
                        }
                      }
                      options={
                        substituableCars
                          ?.filter(c => c.default_location.id === reservation.pickup_location.id)
                          .filter(c => c.id !== reservation.car.id)
                          .map(({ id, vin, model_name, model_type, license_plate, default_location }) => ({
                            value: id,
                            label: `
                              ${model_name} ${model_type}
                              (${vin} ${license_plate} in ${default_location.address}, ${default_location.zipcode})
                            `,
                          }))
                      }
                      placeholder="Select a car"
                      isSearchable
                    />
                  </div>
                </div>

                <div className="d-flex flex-row gap-2">
                  <Typography>
                    Other cars (I&apos;m delivering to this location)
                  </Typography>

                  <div style={{ width: '70%' }}>
                    <Select
                      onChange={
                        (option: any) => {
                          setChangeCarPayload({
                            car_id: parseInt(option.value, 10),
                          });
                          setSelectedCar(substituableCars.find(c => c.id === parseInt(option.value, 10))!);
                        }
                      }
                      options={
                        substituableCars
                          ?.filter(c => c.default_location.id !== reservation.pickup_location.id)
                          .filter(c => c.id !== reservation.car.id)
                          .map(({ id, model_name, model_type, vin, license_plate, default_location }) => ({
                            value: id,
                            label: `
                              ${model_name} ${model_type}
                              (${vin} ${license_plate} in ${default_location.address}, ${default_location.zipcode})
                            `,
                          }))
                      }
                      placeholder="Select a car"
                      isSearchable
                    />
                  </div>
                </div>

                <div>
                  <Typography>
                    {
                      `
                        Current car: ${reservation.car.model_name} ${reservation.car.model_type}
                        (${reservation.car.license_plate} in ${reservation.car.default_location.zipcode})
                      `
                    }
                  </Typography>

                  <Typography>
                    {
                      selectedCar
                        ? `
                            Selected car: ${selectedCar.model_name} ${selectedCar.model_type}
                            (${selectedCar.license_plate} in ${selectedCar.default_location.zipcode})
                          `
                        : 'Selected car:'
                    }
                  </Typography>
                </div>

                {
                  selectedCar && (
                    <div style={{ width: '200px' }}>
                      <Button
                        onClick={handleCarChange}
                        style={{ backgroundColor: variables.red3 }}
                      >
                        Submit car change
                      </Button>
                    </div>
                  )
                }
              </div>
            )
          }

          {
            showDifferentLocationFlow && (
              <div className="d-flex flex-column mt-3 justify-content-center align-items-center gap-3">
                <div className="d-flex flex-column">
                  <Typography>
                    Only do this if guest has agreed to pickup from another location.
                  </Typography>

                  <div>
                    <Select
                      onChange={
                        (option: any) => {
                          const newCar = substituableCars.find(c => c.id === parseInt(option.value, 10))!;
                          setChangeCarPayload({
                            car_id: parseInt(option.value, 10),
                            pickup_location_id: newCar.default_location.id,
                            return_location_id: newCar.default_location.id,
                          });
                          setSelectedCar(newCar);
                        }
                      }
                      options={
                        substituableCars
                          ?.filter(c => c.default_location.id !== reservation.pickup_location.id)
                          .filter(c => c.id !== reservation.car.id)
                          .map(({ id, model_name, model_type, vin, license_plate, default_location }) => ({
                            value: id,
                            label: `
                              ${model_name} ${model_type}
                              (${vin} ${license_plate} in ${default_location.address}, ${default_location.zipcode})
                            `,
                          }))
                      }
                      placeholder="Select a car"
                      isSearchable
                    />
                  </div>
                </div>

                <div>
                  <Typography>
                    {
                      `
                        Current car: ${reservation.car.model_name} ${reservation.car.model_type}
                        (${reservation.car.license_plate} in ${reservation.car.default_location.zipcode})
                      `
                    }
                  </Typography>

                  <Typography>
                    {
                      selectedCar
                        ? `
                            Selected car: ${selectedCar.model_name} ${selectedCar.model_type}
                            (${selectedCar.license_plate} in ${selectedCar.default_location.zipcode})
                          `
                        : 'Selected car:'
                    }
                  </Typography>

                  {
                    selectedCar && (
                      <Typography>
                        {
                          `New location: ${selectedCar.default_location.address}`
                        }
                      </Typography>
                    )
                  }
                </div>

                {
                  selectedCar && (
                    <div>
                      <Button
                        onClick={handleCarChange}
                        style={{ backgroundColor: variables.red3 }}
                      >
                        Submit car change
                      </Button>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default ModifyReservation;
